<template>
  <div class="origin">
    <div class="flex xs12">
      <va-card title="获取原图">
        <div class="flex md12 sm12 xs12">
          <va-input v-model="qrcode" placeholder="对应照片编号" />
        </div>
        <va-button big @click="showPic">原图预览</va-button>
        <va-button big @click="downloadOrigin">下载照片</va-button>
        <va-button big @click="showQRcode">显示二维码</va-button>
        <va-button big @click="printQRcode">打印二维码</va-button>
      </va-card>
      <img :src="oriUrl" style="width: 50%; height: 50%;" />
    </div>
    <va-modal
      v-model="modal"
      title="请扫码下载电子版照片"
      okText="已下载"
      cancelText="取消"
    >
      <div style="position: relative;">
        <!-- <transition> -->
        <img :src="codeurl" style="height: 50vh; max-width: 100%;" />
        <!-- </transition> -->
      </div>
    </va-modal>
  </div>
</template>

<script>
// import axios from 'axios';
import axios from "axios";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      qrcode: "",
      codeurl: "",
      qrcodeErrors: "",
      toastText: "",
      toastDuration: 2500,
      toastPosition: "top-center",
      isToastFullWidth: false,
      oriUrl: require("../../../../../background/1.jpg"),
      modal: false,
    };
  },
  methods: {
    showPic() {
      this.qrcodeErrors = this.qrcode ? [] : ["qrcode is required"];
      if (!this.qrcodeErrors.length) {
        // change the image source

        this.axios
          .get("/v1/picture/queryori/" + this.qrcode)
          .then((res) => {
            console.log(res);
            this.oriUrl = res.data;
          })
          .catch((error) => {
            console.log(error);
            this.toastText = "未找到对应原图";
            this.showToast(this.toastText, {
              position: this.toastPosition,
              duration: this.toastDuration,
              fullWidth: this.isToastFullWidth,
            });
          });
      } else {
        this.toastText = "请输入对应编号";
        this.showToast(this.toastText, {
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        });
      }
    },
    showQRcode() {
      var div = document.createElement("div");
      var code = new QRCode(div, {
        text: this.oriUrl,
        width: 250,
        height: 250,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });
      let canvas = code._el.querySelector("canvas");
      var base64Text = canvas.toDataURL("image/png");
      this.codeurl = base64Text;
      this.modal = true;
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.qrcode + ".jpg"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    downloadOrigin() {
      this.$http({
        method: "get",
        url: this.oriUrl,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },

    printQRcode() {
      const instance1 = axios.create({
        baseURL: "http://localhost:8800",
        timeout: 5000,
      });
      var div2 = document.createElement("div2");
      var code = new QRCode(div2, {
        text: this.oriUrl,
        width: 250,
        height: 250,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });
      let canvas = code._el.querySelector("canvas");
      var base64Text = canvas.toDataURL("image/png");
      let payload = base64Text.slice(base64Text.indexOf("base64,") + 7);
      // console.log(payload);
      this.photoDatas = [];
      this.photoDatas.push({
        type: "text",
        payload:
          "      ==欢迎参观东方明珠==\n \n  请在三天内扫描二维码完成下载\n ",
      });
      this.photoDatas.push({ type: "image", payload: payload });
      this.photoDatas.push({
        type: "text",
        payload: " \n     ----------------------\n ",
      });

      instance1({
        url: "/print",
        method: "post",
        data: { printer: "receipt", data: this.photoDatas },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
